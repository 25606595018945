div.vs-default-react-select {
  margin-bottom: 0;
}

div.vs-data-table-container {
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

div.vs-table-title {
  padding: 12px 0;
  color: #333;
}
