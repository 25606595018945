@import '~antd/dist/antd.css';

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly] {
  cursor: default;
  background-color: inherit;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f1f1f1;
}

button.hideme {
  display: none;
}

.helper {
  display: table;
  cursor: grabbing;
  pointer-events: auto !important;
}

input.ant-input.ant-calendar-picker-input {
  background-color: var(--color-background-default);
  color: var(--color-text-default);
  border: 1px solid #c8ccce;
  cursor: default;
}

input.ant-input.ant-calendar-picker-input:focus,
.vsx-field .Select.is-focused:not(.is-open) > .Select-control {
  outline: 1px solid rgba(73, 197, 176, 0.2);
  border-color: var(--color-background-button-primary-default);
  box-shadow: 0 0 4px rgba(73, 197, 176, 0.4);
}

.vsx-top-nav-layout-title {
  color: #FFF !important;
  margin: 0;
}

.vsx-pagination .vsx-react-select-input input {
  height: auto;
}

/* .ant-calendar-picker-icon {
  color: transparent;
} */

.modal-body {
  max-height: calc(100vh - 280px);
  overflow-y: auto;
}

.vs-list-view-container {
  width: 100%;
}

.vs-internal-sliding-panel {
  height: calc(100vh - var(--nav-comp-height-1));
}

.vs-list-item{
  transition: 0.1s all ease-in-out;
}
.vs-list-item:hover {
  box-shadow: 0 0 6px rgba(0, 0, 0, .15);
}
.popover__title {
  font-size: 24px;
  line-height: 36px;
  text-decoration: none;
  color: rgb(228, 68, 68);
  text-align: center;
}

.popover__wrapper {
  position: relative;
  display: inline-block;
}
.popover__message {
  text-align: left;
}

.popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 34px;
  top:24px;
  transform: translate(0, 10px);
  background-color: #f2f2f2;
  border-left: 4px solid #49c5b1;
  border-radius: 3px;
  padding: 16px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  max-width: 400px;
  width:max-content;
}
.popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: -11px;
  top: 7px;
  border-style: solid;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 12px solid #f2f2f2;
  border-right: 0 solid transparent;
  transition-duration: 0.3s;
  transition-property: transform;
}
.popover__wrapper:hover .popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}


.icon_popover__title {
  text-decoration: none;
  color: rgb(228, 68, 68);
  text-align: center;
}

.icon_popover__wrapper {
  position: relative;
  display: inline-block;
  height:22px;
}
.icon_popover__message {
  text-align: center;
}

.icon_popover__content {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: -29px;
  top:48px;
  transform: translate(0, 10px);
  background-color:black;
  color:white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  max-width: 400px;
  border-radius: 3px;
  width:80px;
}
.icon_popover__content:before {
  position: absolute;
  z-index: -1;
  content: "";
  right: -11px;
  top: 7px;
  transition-duration: 0.3s;
  transition-property: transform;
}
.icon_popover__wrapper:hover .icon_popover__content {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
