.md h3 {
  font-size: 24px;
}

.md>ul>li {
  padding-top: 10px;
}

.md h4 {
  padding-bottom: 10px;
  border-bottom: solid #d0d0d0 1px;
}

.md h5 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
}

.md strong {
  text-decoration: underline;
}

.md blockquote {
  padding: 5px 25px;
  margin: 5px 0 10px;
  background-color: #f9f9f9;
  border-left: 5px solid #048bc6;
  display: inline-block;
}

.md code {
  background-color: #f9f9f9;
  color: #159026;
}
