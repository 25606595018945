.hljs {
  background: transparent;
}
.hljs-highlight {
  font-family: inherit;
  display: inline-block;
  font-size: 14px;
  padding: 5px 5px;
  margin: 5px 1px;
  border-radius: 2px;
  border-width: 2px;
  border-style: solid;
}

.hljs-token {
  font-size: 2em;
  color: #ddd;
  display: none;
}

.hljs-highlight.product-mode-details {
  background: rgba(0, 0, 255, 0.1);
  border: 2px solid rgba(0, 0, 255, 0.5);
  color: #444;
}

.hljs-highlight.prohibited-mode-details {
  border: 2px solid rgba(246, 81, 97, 1);
  background: rgba(246, 81, 97, 0.1);
  color: rgba(246, 81, 97, 1);
}

.hljs-highlight.inferred-prohibited-mode-details {
  border: 2px solid rgba(246, 81, 97, 1);
  background: rgba(246, 81, 97, 0.1);
  color: rgba(246, 81, 97, 1);
}

.hljs-highlight.warning-block-mode-details {
  border: 2px solid #f7c623;
  background: #ffefbb;
  color: #7d5f13;
  font-family: 'Open Sans', sans-serif;
  display: block;
}

.hljs-highlight.required-mode-details {
  border: 2px solid rgba(76, 175, 80, 0.5);
  background: rgba(76, 175, 80, 0.1);
  color: rgba(76, 175, 80, 1);
}


.hljs-highlight.link-mode-details {
  color: #2821f3;
  border: none;
  border-bottom: 2px solid #2821f3;
  padding: 0;
}

.hljs-highlight.tooltip-mode-details {
  border: 2px solid rgba(153, 153, 153, 0.35);
  background: #eee;
  color: #666;
}

.hljs-highlight.tooltip-mode-details::before {
  content: 'Tooltip';
  font-variant-caps: all-small-caps;
  font-size: 1.4em;
  padding-right: 0;
  color: #555;
  position: relative;
  top: -7px;
  left: 2px;
  display: block;
}

.hljs-highlight.offer-mode-details,
.hljs-highlight.ignore-mode-details {
  color: rgba(51, 51, 51, 0.3);
  border: 0;
  border-bottom: 0 solid rgba(51, 51, 51, 0.3);
  border-right: 0 solid rgba(51, 51, 51, 0.3);
  margin-left: -5px;
  margin-right: -5px;
}

.hljs-highlight.ignore-mode-details .hljs-highlight.tooltip-mode-details,
.hljs-highlight.offer-mode-details .hljs-highlight.tooltip-mode-details {
  background-color: transparent;
  border: 2px solid #ddd;
  color: inherit;
}

.hljs-highlight.ignore-mode-details .hljs-highlight.tooltip-mode-details::before,
.hljs-highlight.offer-mode-details .hljs-highlight.tooltip-mode-details::before {
  color: inherit;
}
